@import "../../utils/variables.scss";

div.contactField {

  width: calc(50% - 28px);


  @media(max-width: $mobileBreakpoint) {
    width: 100%;
    margin: 0 auto;
    display: block;
    max-width: 460px;
  }

  & > div, & > input {
    width: 100%;
  }
}

.firstName {

}

.email {

}