body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  display: flex;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 992px) {
  .App {
    flex-direction: column;
    padding: 5px 5px 2rem 5px;
  }
}

@media (min-width: 992px) {
  #form-container {
    margin-left: 230px;
    position: relative;
    width: 100%;
  }
}

.validation-error {
  padding: 1rem;
  color: #c60000;
  display: flex;
  align-items: center;
}

.validation-error .validation-error-icon {
  margin-right: 10px;
}

#question-2 .option-4 > div {
  background-size: 60%;
}

#question-19 > div > div {
  width: 140px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2px;
  z-index: 0;
}

#question-19 > div > div > div:first-child {
  background-size: cover;
  width: 100%;
  border-radius: 3px 3px 0 0;
  margin: 0;
}

#question-19 > div > div:hover > div:first-child {
  width: 100%;
}

#question-19 > div > div > div:nth-child(2) {
  padding: 10px;
  font-size: 0.8rem;
}

#question-19 > div > div.selected {
  padding: 2px;
}

#question-19 > div > div.selected > div:first-child {
  z-index: -1;
  width: calc(100% - 2px);
}

#question-28 .option-1 > div:first-child,
#question-28 .option-2 > div:first-child,
#question-28 .option-3 > div:first-child {
  background-size: 60%;
}

#question-28 .option-4 > div:first-child {
  background-size: 75%;
}

#question-34 .option-1 > div:first-child,
#question-34 .option-2 > div:first-child {
  background-size: 60%;
}

#question-34 .option-3 > div:first-child {
  background-size: 75%;
}

#question-34 .option-4 {
  width: 110px;
  height: 110px;
  padding: 14px 10px;
}

#question-40 > div > div,
#question-41 > div > div {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  max-width: 400px;
}

#question-42 {
  text-align: center;
}

svg.rc-progress-line {
  width: 100%;
}

#valuation-tool-container {
  position: relative;
}

.navigation_sidebar__3rJga {
  list-style-type: none;
  min-width: 230px;
  margin: 0;
  padding: 0; }
  @media (max-width: 992px) {
    .navigation_sidebar__3rJga {
      max-width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 4px;
      border-radius: 3px; } }

@media (min-width: 992px) {
  .navigation_navigationContainer__2vTBP {
    position: fixed; } }

.navigation-item_item__1CeYb {
  padding: 1.5rem 1rem;
  text-align: left;
  color: #232f3e;
  background-color: #f2f2f2;
  margin: 4px 0;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 220ms linear;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }
  .navigation-item_item__1CeYb:not(:last-child):after {
    content: '';
    position: absolute;
    top: 0;
    right: -19px;
    width: 0;
    height: 0;
    border-top: 33px solid transparent;
    border-bottom: 34px solid transparent;
    border-left: 20px solid #f2f2f2;
    transition: border-color 220ms linear; }
  .navigation-item_item__1CeYb.navigation-item_active__1qlbe {
    background-color: #ff9900;
    color: #f2f2f2;
    display: flex; }
    .navigation-item_item__1CeYb.navigation-item_active__1qlbe:after {
      border-left: 20px solid #ff9900; }
  .navigation-item_item__1CeYb.navigation-item_locked__2jm60 {
    cursor: not-allowed; }
  .navigation-item_item__1CeYb:not(.navigation-item_locked__2jm60):hover {
    background-color: #232f3e;
    color: #f2f2f2; }
    .navigation-item_item__1CeYb:not(.navigation-item_locked__2jm60):hover:after {
      border-left: 20px solid #232f3e; }
  @media (max-width: 992px) {
    .navigation-item_item__1CeYb {
      width: calc(50% - 2.5rem);
      margin: 0;
      border-radius: 0;
      height: 30px;
      padding: 0.8rem 1rem;
      display: none;
      align-items: center;
      border-bottom: 2px solid #fff;
      /*&:not(:last-child):after {
      display: none;
      right: -20px;
      width: 0;
      height: 0;
      border-top-width: 28px;
      border-bottom-width: 27.5px;
    }

    &:nth-child(odd) {
      z-index: 2;
    }

    &:nth-child(even) {
      padding: 0.8rem 1rem 0.8rem 2rem;
      width: calc(50% - 2.5rem);

      &:after {
        content: none;
      }
    }

    &:last-child:nth-child(odd) {
      width: 100%;
      justify-content: center;
    }

    &:first-child {
      border-radius: 3px 0 0 0;
    }

    &:nth-child(2) {
      border-radius: 0 3px 0 0;
    }

    &:nth-last-child(2):nth-child(odd) {
      border-radius: 0 0 0 3px;
    }

    &:last-child {
      border-radius: 0 0 3px 3px;
    }

    &:last-child:nth-child(even) {
      border-radius: 0 0 3px 0;
    }*/ }
      .navigation-item_item__1CeYb:after {
        border: none; }
      .navigation-item_item__1CeYb.navigation-item_active__1qlbe {
        display: flex;
        width: 100%; }
        .navigation-item_item__1CeYb.navigation-item_active__1qlbe:after {
          border-left: none; }
      .navigation-item_item__1CeYb:not(.navigation-item_locked__2jm60):hover:after {
        border: none; }
      .navigation-item_item__1CeYb .navigation-item_number__2P9y- {
        font-size: 1.2rem; }
      .navigation-item_item__1CeYb .navigation-item_dot__2lF95 {
        margin-right: 10px; } }

.multiform_form__bWhsf {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 10px 4px;
  text-align: left;
  max-width: 1220px;
  position: relative; }
  @media (min-width: 992px) {
    .multiform_form__bWhsf {
      margin: 4px 50px 20px 50px;
      padding: 1rem 2rem;
      min-width: 80%;
      min-height: auto; } }

.step_fields__2N_MH {
  padding: 0 6rem; }
  @media (max-width: 992px) {
    .step_fields__2N_MH {
      padding: 0; } }

.step_field__31DcQ {
  padding: 20px 30px; }
  .step_field__31DcQ.step_radio__rtFvT {
    padding: 20px 10px; }
  @media (max-width: 992px) {
    .step_field__31DcQ {
      padding: 10px 30px; }
      .step_field__31DcQ.step_radio__rtFvT, .step_field__31DcQ.step_multiselect__3FjiZ, .step_field__31DcQ.step_date__2gMRZ {
        padding: 0; } }

.step_divider__3FGqM {
  border: 1px solid #ff9900; }

.step_headline__38S2k {
  font-weight: normal;
  margin-top: 0; }
  @media (max-width: 992px) {
    .step_headline__38S2k {
      margin-top: 15px; } }

button.step_button__Ympjv {
  padding: 0.8rem 4rem;
  margin: 2rem auto;
  width: 50%;
  max-width: 500px;
  display: block; }
  @media (max-width: 992px) {
    button.step_button__Ympjv {
      width: 95%;
      max-width: 95%; } }
  button.step_button__Ympjv.step_btnOne__1qKH9 {
    background-color: #ff9900;
    color: #f2f2f2; }
    button.step_button__Ympjv.step_btnOne__1qKH9:hover {
      background-color: #ffa31a; }
  button.step_button__Ympjv.step_btnTwo__BGk22 {
    background-color: #232f3e;
    color: #f2f2f2; }
    button.step_button__Ympjv.step_btnTwo__BGk22:hover {
      background-color: #2c3b4e; }

.question_question___qfJ5 {
  display: flex;
  align-items: flex-start;
  color: #35485f; }
  .question_question___qfJ5 .question_tooltip__2Ksle {
    margin-top: 1rem; }
    @media (max-width: 992px) {
      .question_question___qfJ5 .question_tooltip__2Ksle {
        font-size: 2rem; } }

.question_title__1OeLv {
  font-weight: normal; }

.question_tooltip__2Ksle {
  margin-right: 10px;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  color: rgba(35, 47, 62, 0.5); }

.question_tooltipMessage__mEOwv {
  z-index: 2; }
  .question_tooltipMessage__mEOwv > div {
    font-size: 0.8rem; }

.datefield_date__1hGRs > div {
  width: 400px;
  max-width: none;
  margin: 0 auto; }
  @media (max-width: 992px) {
    .datefield_date__1hGRs > div {
      margin: 0 auto; } }
  @media (max-width: 512px) {
    .datefield_date__1hGRs > div {
      width: 100%; } }
  .datefield_date__1hGRs > div > span:first-child {
    color: rgba(35, 47, 62, 0.6);
    display: none; }
  .datefield_date__1hGRs > div > div:nth-child(2) > span {
    color: #ff9900;
    text-shadow: 1px 1px 0 rgba(35, 47, 62, 0.2); }
  .datefield_date__1hGRs > div > div:nth-child(2) > div[class] {
    color: rgba(35, 47, 62, 0.5);
    display: flex;
    align-items: center;
    width: 100%; }
    .datefield_date__1hGRs > div > div:nth-child(2) > div[class] i {
      position: absolute;
      outline: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
      top: -10px;
      padding: 10px 15px;
      border: 1px solid rgba(242, 242, 242, 0.8);
      border-radius: 50%;
      cursor: pointer; }
      .datefield_date__1hGRs > div > div:nth-child(2) > div[class] i:hover {
        color: rgba(35, 47, 62, 0.9);
        border: 1px solid #f2f2f2; }
      .datefield_date__1hGRs > div > div:nth-child(2) > div[class] i:first-child {
        left: 15px;
        right: auto; }
        .datefield_date__1hGRs > div > div:nth-child(2) > div[class] i:first-child::before {
          position: relative;
          top: 1px;
          left: -1px; }
      .datefield_date__1hGRs > div > div:nth-child(2) > div[class] i:nth-child(2) {
        left: auto;
        right: 15px; }
        .datefield_date__1hGRs > div > div:nth-child(2) > div[class] i:nth-child(2)::before {
          position: relative;
          top: 1px;
          left: 3px; }
  .datefield_date__1hGRs > div > div:nth-child(3) > div > div {
    background-color: transparent;
    border: 1px solid rgba(35, 47, 62, 0.2);
    border-radius: 3px;
    color: #666666;
    outline: none;
    max-width: 70px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent; }
    .datefield_date__1hGRs > div > div:nth-child(3) > div > div:hover {
      background-color: #232f3e;
      color: #fff; }
    .datefield_date__1hGRs > div > div:nth-child(3) > div > div.selected {
      background-color: #ff9900;
      border: 1px solid rgba(255, 153, 0, 0.5); }
  .datefield_date__1hGRs > div > div:nth-child(3) > div {
    display: flex;
    justify-content: space-evenly; }
    @media (max-width: 320px) {
      .datefield_date__1hGRs > div > div:nth-child(3) > div > div {
        margin: 10px 4px; } }

.radiofield_container__HSgg6 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.radiofield_option__13OD8 {
  text-align: center;
  margin: 10px 25px;
  box-shadow: inset 0 0 0 1px #b3b3b3;
  padding: 10px;
  border-radius: 5px;
  min-width: 120px;
  cursor: pointer;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f4f4f4+0,ffffff+100 */
  background: #f4f4f4;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #f4f4f4 0%, white 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#ffffff',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }
  .radiofield_option__13OD8:hover {
    box-shadow: inset 0 0 0 2px rgba(35, 47, 62, 0.3); }
  .radiofield_option__13OD8.radiofield_selected__3Ryai {
    box-shadow: inset 0 0 0 3px #ff9900;
    background: rgba(255, 153, 0, 0.1); }
  @media (max-width: 992px) {
    .radiofield_option__13OD8 {
      margin: 10px;
      min-width: 110px;
      max-width: 110px; } }
  .radiofield_option__13OD8:not(.radiofield_hasImage__23enB) {
    padding: 1rem 2rem;
    width: calc(50% - 4rem - 50px);
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 1092px) {
      .radiofield_option__13OD8:not(.radiofield_hasImage__23enB) {
        margin: 10px; } }
    @media (max-width: 992px) {
      .radiofield_option__13OD8:not(.radiofield_hasImage__23enB) {
        width: calc(85% - 4rem); } }

.radiofield_image__3GltK {
  width: 100px;
  height: 100px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto; }
  @media (max-width: 992px) {
    .radiofield_image__3GltK {
      width: 100px;
      height: 90px; } }

@media (min-width: 992px) {
  div.textfield_text__YGwXN > div input {
    cursor: pointer; } }

@media (max-width: 586px) {
  div.textfield_text__YGwXN {
    width: 100%;
    margin-left: 0; } }

.checkboxfield_label__2EzY3 * {
  font-size: 1rem; }

.checkboxfield_label__2EzY3 a {
  color: #ff9900; }

.note_note__1PJuz {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 3rem;
  margin: 1rem 2rem;
  text-align: center;
  color: #35485f;
  font-style: italic;
  border: 1px solid rgba(35, 47, 62, 0.2);
  border-radius: 3px; }
  @media (max-width: 992px) {
    .note_note__1PJuz {
      padding: 1rem;
      margin: 0;
      font-size: 0.9rem; } }

.thankyouscreen_divider__wP_MZ {
  border: 1px solid #ff9900; }

.thankyouscreen_headline__235vH {
  font-weight: normal;
  margin-top: 0; }
  @media (max-width: 992px) {
    .thankyouscreen_headline__235vH {
      margin-top: 15px; } }

.thankyouscreen_container__1DmJv {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin: 1rem;
  padding: 1rem;
  text-align: left;
  max-width: 1220px; }
  @media (min-width: 992px) {
    .thankyouscreen_container__1DmJv {
      margin: 35px 50px 20px 50px;
      padding: 1rem 2rem;
      min-width: 80%; } }

@media (max-width: 992px) {
  .intermission-dialog_container__1qYRY > div > div:first-child {
    margin: 25px; } }

div.intermission-dialog_text__2WgGc {
  color: rgba(35, 47, 62, 0.8); }

button.intermission-dialog_button__1e0oT {
  padding: 0.8rem 4rem;
  margin: 2rem auto;
  width: 50%;
  max-width: 500px;
  display: block;
  background-color: #ff9900;
  color: #f2f2f2; }
  button.intermission-dialog_button__1e0oT:hover {
    background-color: #ffa31a; }
  @media (max-width: 992px) {
    button.intermission-dialog_button__1e0oT {
      width: 70%;
      max-width: 80%;
      padding: 0.8rem 1rem; } }

.errorscreen_divider__bzdOH {
  border: 1px solid #ff9900; }

.errorscreen_headline__2KXUB {
  font-weight: normal;
  margin-top: 0; }
  @media (max-width: 992px) {
    .errorscreen_headline__2KXUB {
      margin-top: 15px; } }

.errorscreen_container__1SwM8 {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin: 1rem;
  padding: 1rem;
  text-align: left;
  max-width: 1220px; }
  @media (min-width: 992px) {
    .errorscreen_container__1SwM8 {
      margin: 35px 50px 20px 50px;
      padding: 1rem 2rem;
      min-width: 80%; } }

.progressbar_progress__3bx0c {
  text-align: left;
  padding: 0.8rem;
  margin-top: 5rem; }
  .progressbar_progress__3bx0c .progressbar_label__3-dw8 {
    color: #232f3e;
    font-size: 1.2rem; }
    .progressbar_progress__3bx0c .progressbar_label__3-dw8 span {
      margin-left: 5px; }
  @media (max-width: 992px) {
    .progressbar_progress__3bx0c {
      position: absolute;
      bottom: 132px;
      width: calc(100% - 26px); } }

.continue-later_container__1hU0O {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,0.75+34,1+100 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 34%, white 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
  /* IE6-9 */
  padding: 2.5rem 0 1.5rem 0;
  display: flex;
  justify-content: center; }
  @media (max-width: 992px) {
    .continue-later_container__1hU0O {
      padding: 2.5rem 0.5rem 1.5rem 0.5rem; } }
  .continue-later_container__1hU0O button {
    background-color: #fff;
    color: #ff9900;
    border: 1px solid #ff9900;
    width: 100%;
    padding: 0.8rem 0; }
    .continue-later_container__1hU0O button:hover {
      background-color: #ffa31a;
      color: #f2f2f2;
      border: 1px solid #fff; }

.continue-later_dialogContainer__3G0BM {
  padding: 1rem 2rem;
  max-width: 500px; }
  @media (max-width: 992px) {
    .continue-later_dialogContainer__3G0BM {
      padding: 1rem 1.5rem; } }
  .continue-later_dialogContainer__3G0BM .continue-later_title__3kqIL {
    padding: 0 0 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .continue-later_dialogContainer__3G0BM .continue-later_content__2ocqI {
    padding: 1rem 0; }
    .continue-later_dialogContainer__3G0BM .continue-later_content__2ocqI .continue-later_email__1AwTy {
      margin: 1.2rem 0 0.4rem 0;
      width: 100%;
      max-width: none; }
    .continue-later_dialogContainer__3G0BM .continue-later_content__2ocqI .continue-later_validationContainer__EQi29 {
      height: 24px; }
      .continue-later_dialogContainer__3G0BM .continue-later_content__2ocqI .continue-later_validationContainer__EQi29 .continue-later_validationError__22W1v {
        color: #c60000; }
  .continue-later_dialogContainer__3G0BM .continue-later_actions__NNrQi {
    margin: 0;
    display: flex;
    flex-direction: column; }
    .continue-later_dialogContainer__3G0BM .continue-later_actions__NNrQi button {
      padding: 0.8rem 4rem;
      margin: 0.2rem 0 1rem 0;
      width: 100%;
      display: block; }
      @media (max-width: 992px) {
        .continue-later_dialogContainer__3G0BM .continue-later_actions__NNrQi button {
          padding: 0.8rem 1rem; } }
      .continue-later_dialogContainer__3G0BM .continue-later_actions__NNrQi button.continue-later_buttonSend__cOYaK {
        background-color: #ff9900;
        color: #f2f2f2; }
        .continue-later_dialogContainer__3G0BM .continue-later_actions__NNrQi button.continue-later_buttonSend__cOYaK:hover {
          background-color: #ffa31a; }

div.contactfield_contactField__YIzvh {
  width: calc(50% - 28px); }
  @media (max-width: 992px) {
    div.contactfield_contactField__YIzvh {
      width: 100%;
      margin: 0 auto;
      display: block;
      max-width: 460px; } }
  div.contactfield_contactField__YIzvh > div, div.contactfield_contactField__YIzvh > input {
    width: 100%; }

