@import '../../utils/variables.scss';

.item {
  padding: 1.5rem 1rem;
  text-align: left;
  color: $accentColor;
  background-color: $white;
  margin: 4px 0;
  cursor: pointer;
  border-radius: 3px;

  -webkit-transition: background-color 220ms linear;
  -ms-transition: background-color 220ms linear;
  transition: background-color 220ms linear;
  position: relative;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  &:not(:last-child):after {
    content: '';
    position: absolute;
    top: 0;
    right: -19px;
    width: 0;
    height: 0;
    border-top: 33px solid transparent;
    border-bottom: 34px solid transparent;
    border-left: 20px solid $white;

    -webkit-transition: border-color 220ms linear;
    -ms-transition: border-color 220ms linear;
    transition: border-color 220ms linear;
  }

  &.active {
    background-color: $primaryColor;
    color: $white;
    display: flex;

    &:after {
      border-left: 20px solid $primaryColor;
    }
  }

  &.locked {
    cursor: not-allowed;
  }

  &:not(.locked):hover {
    background-color: $accentColor;
    color: $white;

    &:after {
      border-left: 20px solid $accentColor;
    }
  }

  @media(max-width: $mobileBreakpoint) {
    width: calc(50% - 2.5rem);
    margin: 0;
    border-radius: 0;
    height: 30px;
    padding: 0.8rem 1rem;
    //display: flex;
    display: none;
    align-items: center;
    border-bottom: 2px solid #fff;

    &:after {
      border: none;
    }

    &.active {
      display: flex;
      width: 100%;

      &:after {
        border-left: none;
      }
    }

    &:not(.locked):hover {

      &:after {
        border: none;
      }
    }

    /*&:not(:last-child):after {
      display: none;
      right: -20px;
      width: 0;
      height: 0;
      border-top-width: 28px;
      border-bottom-width: 27.5px;
    }

    &:nth-child(odd) {
      z-index: 2;
    }

    &:nth-child(even) {
      padding: 0.8rem 1rem 0.8rem 2rem;
      width: calc(50% - 2.5rem);

      &:after {
        content: none;
      }
    }

    &:last-child:nth-child(odd) {
      width: 100%;
      justify-content: center;
    }

    &:first-child {
      border-radius: 3px 0 0 0;
    }

    &:nth-child(2) {
      border-radius: 0 3px 0 0;
    }

    &:nth-last-child(2):nth-child(odd) {
      border-radius: 0 0 0 3px;
    }

    &:last-child {
      border-radius: 0 0 3px 3px;
    }

    &:last-child:nth-child(even) {
      border-radius: 0 0 3px 0;
    }*/

    .number {
      font-size: 1.2rem;
    }

    .dot {
      margin-right: 10px;
      //visibility: hidden;
    }
  }
}
