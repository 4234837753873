@import "../../utils/variables.scss";

.container {
  @media(max-width: $mobileBreakpoint) {
    & > div > div:first-child {
      margin: 25px;
    }
  }
}

div.title {

}

div.text {
  color: rgba($accentColor, 0.8);
}

button.button {
  padding: 0.8rem 4rem;
  margin: 2rem auto;
  width: 50%;
  max-width: 500px;
  display: block;

  background-color: $primaryColor;
  color: $white;

  &:hover {
    background-color: lighten($primaryColor, 5%);
  }

  @media(max-width: $mobileBreakpoint) {
    width: 70%;
    max-width:80%;
    padding: 0.8rem 1rem;
  }
}