@import "../../utils/variables.scss";

.progress {
  text-align: left;
  padding: 0.8rem;
  margin-top: 5rem;
  // position: sticky;
  // bottom: 0;

  .label {
    color: $accentColor;
    font-size: 1.2rem;

    span {
      margin-left: 5px;
    }
  }

  @media (max-width: $mobileBreakpoint) {
    position: absolute;
    bottom: 132px;
    width: calc(100% - 26px);
  }
}
