.App {
  text-align: center;
  display: flex;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 992px) {
  .App {
    flex-direction: column;
    padding: 5px 5px 2rem 5px;
  }
}

@media (min-width: 992px) {
  #form-container {
    margin-left: 230px;
    position: relative;
    width: 100%;
  }
}

.validation-error {
  padding: 1rem;
  color: #c60000;
  display: flex;
  align-items: center;
}

.validation-error .validation-error-icon {
  margin-right: 10px;
}

#question-2 .option-4 > div {
  background-size: 60%;
}

#question-19 > div > div {
  width: 140px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2px;
  z-index: 0;
}

#question-19 > div > div > div:first-child {
  background-size: cover;
  width: 100%;
  border-radius: 3px 3px 0 0;
  margin: 0;
}

#question-19 > div > div:hover > div:first-child {
  width: 100%;
}

#question-19 > div > div > div:nth-child(2) {
  padding: 10px;
  font-size: 0.8rem;
}

#question-19 > div > div.selected {
  padding: 2px;
}

#question-19 > div > div.selected > div:first-child {
  z-index: -1;
  width: calc(100% - 2px);
}

#question-28 .option-1 > div:first-child,
#question-28 .option-2 > div:first-child,
#question-28 .option-3 > div:first-child {
  background-size: 60%;
}

#question-28 .option-4 > div:first-child {
  background-size: 75%;
}

#question-34 .option-1 > div:first-child,
#question-34 .option-2 > div:first-child {
  background-size: 60%;
}

#question-34 .option-3 > div:first-child {
  background-size: 75%;
}

#question-34 .option-4 {
  width: 110px;
  height: 110px;
  padding: 14px 10px;
}

#question-40 > div > div,
#question-41 > div > div {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  max-width: 400px;
}

#question-42 {
  text-align: center;
}

svg.rc-progress-line {
  width: 100%;
}

#valuation-tool-container {
  position: relative;
}
