@import "../../utils/variables.scss";

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.option {
  text-align: center;
  margin: 10px 25px;
  box-shadow: inset 0 0 0 1px lighten($black, 70%);
  padding: 10px;
  border-radius: 5px;
  min-width: 120px;
  cursor: pointer;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f4f4f4+0,ffffff+100 */
  background: rgb(244,244,244); /* Old browsers */
  background: -moz-linear-gradient(45deg, rgba(244,244,244,1) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, rgba(244,244,244,1) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, rgba(244,244,244,1) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  &:hover {
    box-shadow: inset 0 0 0 2px rgba($accentColor, 0.3);
  }

  &.selected {
    box-shadow: inset 0 0 0 3px $primaryColor;
    background: rgba($primaryColor, 0.1);
  }

  @media(max-width: $mobileBreakpoint) {
    margin: 10px;
    min-width: 110px;
    max-width: 110px;
  }

  .hasImage {

  }

  &:not(.hasImage) {
    padding: 1rem 2rem;
    width: calc(50% - 4rem - 50px);
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 1092px) {
      margin: 10px;
    }

    @media(max-width: $mobileBreakpoint) {
      width: calc(85% - 4rem);
    }
  }
}

.image {
  width: 100px;
  height: 100px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;

  @media(max-width: $mobileBreakpoint) {
    width: 100px;
    height: 90px;
  }
}