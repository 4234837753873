@import "../../utils/variables.scss";

div.text {

  @media(min-width: $mobileBreakpoint) {

    & > div {
      input {
        cursor: pointer;
      }
    }
  }

  @media(max-width: 586px) {
    width: 100%;
    margin-left: 0;
  }
}