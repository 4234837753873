@import "../../utils/variables.scss";

.step {

}

.fields {
  padding: 0 6rem;

  @media(max-width: $mobileBreakpoint) {
    padding: 0;
  }
}


.field {
  padding: 20px 30px;

  &.radio {
    padding: 20px 10px;
  }

  @media(max-width: $mobileBreakpoint) {
    padding: 10px 30px;

    &.radio, &.multiselect, &.date {
      padding: 0;
    }
  }
}

.divider {
  border: 1px solid $primaryColor;
}

.headline {
  font-weight: normal;
  margin-top: 0;

  @media(max-width: $mobileBreakpoint) {
    margin-top: 15px;
  }
}

button.button {
  padding: 0.8rem 4rem;
  margin: 2rem auto;
  width: 50%;
  max-width: 500px;
  display: block;

  @media(max-width: $mobileBreakpoint) {
    width: 95%;
    max-width: 95%;
  }

  &.btnOne {
    background-color: $primaryColor;
    color: $white;


    &:hover {
      background-color: lighten($primaryColor, 5%);
    }
  }

  &.btnTwo {
    background-color: $accentColor;
    color: $white;


    &:hover {
      background-color: lighten($accentColor, 5%);
    }
  }
}