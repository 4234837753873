@import "../../utils/variables.scss";

.divider {
  border: 1px solid $primaryColor;
}

.headline {
  font-weight: normal;
  margin-top: 0;

  @media(max-width: $mobileBreakpoint) {
    margin-top: 15px;
  }
}

.container {
  border: 1px solid darken($white, 10%);
  border-radius: 5px;
  margin: 1rem;
  padding: 1rem;
  text-align: left;
  max-width: 1220px;

  @media(min-width: $mobileBreakpoint) {
    margin: 35px 50px 20px 50px;
    padding: 1rem 2rem;
    min-width: 80%;
  }
}