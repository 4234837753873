@import "../../utils/variables.scss";

.form {
  border: 1px solid darken($white, 10%);
  border-radius: 5px;
  padding: 0.5rem;
  margin: 10px 4px;
  text-align: left;
  max-width: 1220px;
  position: relative;

  @media(min-width: $mobileBreakpoint) {
    margin: 4px 50px 20px 50px;
    padding: 1rem 2rem;
    min-width: 80%;
    min-height: auto;
  }
}
