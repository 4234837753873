@import "../../utils/variables.scss";

.container {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,0.75+34,1+100 */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.75) 34%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.75) 34%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.75) 34%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0); /* IE6-9 */

  padding: 2.5rem 0 1.5rem 0;

  @media (max-width: $mobileBreakpoint) {
    padding: 2.5rem 0.5rem 1.5rem 0.5rem;
  }

  display: flex;
  justify-content: center;
  // position: sticky;
  // bottom: 0;

  button {
    background-color: #fff;
    color: $primaryColor;
    border: 1px solid $primaryColor;
    width: 100%;
    padding: 0.8rem 0;

    &:hover {
      background-color: lighten($primaryColor, 5%);
      color: $white;
      border: 1px solid #fff;
    }
  }
}

.dialogContainer {
  padding: 1rem 2rem;
  max-width: 500px;

  @media (max-width: $mobileBreakpoint) {
    padding: 1rem 1.5rem;
  }

  .title {
    padding: 0 0 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content {
    padding: 1rem 0;

    .email {
      margin: 1.2rem 0 0.4rem 0;
      width: 100%;
      max-width: none;
    }

    .validationContainer {
      height: 24px;

      .validationError {
        color: #c60000;
      }
    }
  }

  .actions {
    margin: 0;
    display: flex;
    flex-direction: column;

    button {
      padding: 0.8rem 4rem;
      margin: 0.2rem 0 1rem 0;
      width: 100%;
      display: block;

      @media (max-width: $mobileBreakpoint) {
        padding: 0.8rem 1rem;
      }

      &.buttonSend {
        background-color: $primaryColor;
        color: $white;

        &:hover {
          background-color: lighten($primaryColor, 5%);
        }
      }
    }
  }
}
