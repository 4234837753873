@import "../../utils/variables.scss";

.date {

   & > div {
    width: 400px;
    max-width: none;
    margin: 0 auto;

     @media(max-width: $mobileBreakpoint) {
       margin: 0 auto;
     }

     @media(max-width: 512px) {
       width: 100%;
     }

     //Caption
     & > span:first-child {
       color: rgba($accentColor, 0.6);
       display: none;
     }

     //Actions and year container
     & > div:nth-child(2) {

       //Year
       & > span {
         color: $primaryColor;
         text-shadow: 1px 1px 0 rgba($accentColor, 0.2);
       }

       //Arrows
       & > div[class] {
         color: rgba($accentColor, 0.5);
         display: flex;
         align-items: center;
         width: 100%;

         i {
           position: absolute;
           outline: none;
           -webkit-tap-highlight-color: rgba(0,0,0,0);
           -webkit-tap-highlight-color: transparent;
           top: -10px;
           padding: 10px 15px;
           border: 1px solid rgba($white, 0.8);
           border-radius: 50%;

           cursor: pointer;

           &:hover {
             color: rgba($accentColor, 0.9);
             border: 1px solid $white;
           }

           &:first-child {
             left: 15px;
             right: auto;

             &::before {
               position: relative;
               top: 1px;
               left: -1px;
             }
           }

           &:nth-child(2) {
             left: auto;
             right: 15px;

             &::before {
               position: relative;
               top: 1px;
               left: 3px;
             }
           }
         }
       }
     }

     // Month boxes
     & > div:nth-child(3) > div > div {
       background-color: transparent;
       border:1px solid rgba($accentColor, 0.2);
       border-radius: 3px;
       color: lighten($black, 40%);
       outline: none;
       max-width: 70px;
       -webkit-tap-highlight-color: rgba(0,0,0,0);
       -webkit-tap-highlight-color: transparent;

       &:hover {
         background-color: $accentColor;
         color: #fff;
       }

       &:global.selected {
         background-color: $primaryColor;
         border:1px solid rgba($primaryColor, 0.5);
       }
     }

     & > div:nth-child(3) > div {
       display: flex;
       justify-content: space-evenly;

       @media(max-width: 320px) {
         & > div {
           margin: 10px 4px;
         }
       }
     }
  }
}