@import "../../utils/variables";

.label {

  & * {
    font-size: 1rem;
  }

  a {
    color: $primaryColor;
  }
}