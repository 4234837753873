@import "../../utils/variables.scss";

.question {
  display: flex;
  align-items: flex-start;
  color: lighten($accentColor, 10%);

  .tooltip {
    margin-top: 1rem;

    @media(max-width: $mobileBreakpoint) {
      font-size: 2rem;
    }
  }
}

.title {
  font-weight: normal;
}

.tooltip {
  margin-right: 10px;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  color: rgba($accentColor, 0.5);
}

.tooltipMessage {

  z-index: 2;

  & > div {
    font-size: 0.8rem;
  }
}
