@import "../../utils/variables.scss";

.sidebar {
  list-style-type: none;
  min-width: 230px;
  margin: 0;
  padding: 0;

  @media(max-width: $mobileBreakpoint) {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 4px;
    border-radius: 3px;
  }
}

.navigationContainer {
  @media(min-width: $mobileBreakpoint) {
    position: fixed;
  }
}