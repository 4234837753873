@import "../../utils/variables.scss";

.note {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 3rem;
  margin: 1rem 2rem;
  text-align: center;
  color: lighten($accentColor, 10%);
  font-style: italic;
  border: 1px solid rgba($accentColor, 0.2);
  border-radius: 3px;

  @media(max-width: $mobileBreakpoint) {
    padding: 1rem;
    margin: 0;
    font-size: 0.9rem;
  }

}
